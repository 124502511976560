/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
 

.disponibilidad-tienda-container {
    .localizaciones {
        li {
            &::before {
                display: none;
            }

            &.tienda {
                border-bottom: 1px solid black;
            }
        }
    }

    & .closed {
        display: none;
    }

    .tienda-container {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        font-size: 12px;

        .tienda-subcontainer {
            cursor: pointer;
            font-weight: bold;
        }

        & .opened {
            display: block;
        }

        & .iconShowInfo {
            cursor: pointer;
            margin-inline-end: 20px;
            width: 17px;
            height: 16px;
        }

        & .addressLine {
            display: flex;

            p {
                font-size: 12px;
            }
        }

        & .phoneNumber {
            font-size: 12px;

            &:hover {
                text-decoration: underline;
                color: #0d233f;
            }
        }

        & .toMapButton {
            cursor: pointer;
            font-weight: bold;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }

        & .addressIcon, .phoneIcon, .locationIcon {
            width: 17px;
            height: 17px;
            margin-inline-end: 10px;
        }
        
        & .addressIcon {
            margin-block-start: 10px;
        }
    }

    .mostrar-mas {
        cursor: pointer;
        font-weight: bold;
        margin-block-start: 10px;
        text-align: center;
        text-decoration: underline;
        font-size: 14px;
    }
}
